import styled from "styled-components"
import { colors } from "../../styles/colors"

export const TestimonialWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #303030;
  padding: 2rem;

`

export const TestimonialContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  max-width: 58.75rem;
  height: 100%;
  padding: 2rem;
`

export const TestimonialTitle = styled.h2`
  font-size: 1.62rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.mainFont};
  text-align: center;
`

export const TestimonialText = styled.q`
  quotes: none;
  text-align: center;
  color: ${colors.main};
  border: none;
  font-size: 1.06rem;
  line-height: 1.75rem;
  margin: 0 0 1.25rem;
  padding: 0;
  font-style: italic;
  position: relative;
  white-space: pre-wrap;
`

export const TestimonialPerson = styled.p`
  color: ${colors.secondFont};
  margin-top: 10px;
  text-align: center;
  > span {
    font-weight: bold;
    color: ${colors.mainFont};
    margin-right: 3px;

  }
`

export const ReadMoreButton = styled.a`
  background-color: transparent;
  color: grey;
  cursor: pointer;
  font-weight: bold;
  margin: 0 15px
`
