export const sloganContentData = [
  {
    text:
      "Gostaríamos de deixar aqui o nosso agradecimento pela parceria com a Susana " + 
      "da SG Contábil estamos muito satisfeitos com os serviços por ela prestados e " +
      "fazemos questão de ressaltar que o seu trabalho é extremamente importante para " +
      "nossa empresa tínhamos diversos  problemas com  a parte fiscal, contábil e " +
      "financeiro e  a Susana  da SG nos apresentou soluções, sempre nos orientando " +
      "a forma mais correta de fazer sem perder prazos e correr riscos, está sempre " +
      "atenta as mudanças da lei e não deixa de passar informações importantes para " +
      "a empresa nos ajudando muito.\n" +
      "Sua forma clara de resolver as coisas sempre com muita propriedade no que " +
      "fala nunca nos deixa dúvidas bem como honestidade, transparência e muita " +
      "segurança.\n" +
      " Hoje podemos dizer que nossa empresa está mais correta, e preparada para " +
      "tomadas de decisões no dia a dia.\n" +
      "Com certeza recomendaria a SG Contábil para os demais empresários assim " +
      "como já o fiz, ótimo escritório com grandes benefícios e nos proporciona ajuda " +
      "no crescimento da empresa.",
    author: "Isabel Camilo da Silva",
    role: "Multartec Refrigeração e Climatização.",
  },
  {
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate, neque a laoreet dignissim, diam metus ullamcorper erat, vitae pretium ligula turpis eget sapien. Nunc a odio ex. Aenean cursus arcu sit amet ipsum eleifend, a pellentesque ex pellentesque. Cras tincidunt porttitor neque quis varius. Nunc vitae vehicula nibh. Etiam iaculis magna ac augue aliquam, id porttitor odio imperdiet. Sed eget aliquet nibh, a viverra ex.",
    author: "John Doe",
    role: "Empresário",
  },
  {
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate, neque a laoreet dignissim, diam metus ullamcorper erat, vitae pretium ligula turpis eget sapien. Nunc a odio ex. Aenean cursus arcu sit amet ipsum eleifend, a pellentesque ex pellentesque. Cras tincidunt porttitor neque quis varius. Nunc vitae vehicula nibh. Etiam iaculis magna ac augue aliquam, id porttitor odio imperdiet. Sed eget aliquet nibh, a viverra ex.",
    author: "John Doe",
    role: "Empresário",
  },
  {
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate, neque a laoreet dignissim, diam metus ullamcorper erat, vitae pretium ligula turpis eget sapien. Nunc a odio ex. Aenean cursus arcu sit amet ipsum eleifend, a pellentesque ex pellentesque. Cras tincidunt porttitor neque quis varius. Nunc vitae vehicula nibh. Etiam iaculis magna ac augue aliquam, id porttitor odio imperdiet. Sed eget aliquet nibh, a viverra ex.",
    author: "John Doe",
    role: "Empresário",
  }
]
