import styled from "styled-components"
import media from "styled-media-query"
import { colors } from "../../styles/colors"

export const PanelWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
`

export const ImageWrapper = styled.div`
  div.gatsby-image-wrapper {
    width: 100%;
    height: 100vh;
  }
`

export const PanelShadow = styled.div`
  top: 0;
  left: 0;
  background: #000;
  height: 100%;
  width: 100%;
  opacity: 45%;
  position: absolute;
`

export const PanelContentLeft = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;

  top: 0;
  left: 0;

  flex-direction: column;
  min-height: 45rem;
  max-height: 45rem;
  width: 60%;
  
  ${media.lessThan("medium")`
    min-height: 50%;
    max-height: 50%;
    width: 100%;
  `}
`

export const PanelContentRight = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;

  top: 2rem;
  left: 60%;

  flex-direction: column;
  min-height: 45rem;
  max-height: 45rem;
  width: 40%;
  z-index:1;
  
  ${media.lessThan("medium")`
    min-height: 50%;
    max-height: 50%;
    top: 50%;
    left: 0%;
    width: 100%;
  `}
`

export const PanelSlogan = styled.strong`
  font-size: 36px;
  font-style: normal;
  font-weight: normal;
  color: ${colors.mainFont};
  text-align: center;
  z-index: 1;
  max-width: 35rem;

  ${media.lessThan("small")`
    font-size: 2rem;
  `}
`

export const PanelSignUp = styled.main`
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  color: ${colors.mainFont};
  text-align: center;
  z-index: 1;
  margin-top: 2rem;

  ${media.lessThan("small")`
    font-size: 1rem;
  `}
`
