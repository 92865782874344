import styled from "styled-components"
import media from "styled-media-query"
import { colors } from "../../styles/colors"
import { Link } from "gatsby"

export const CategoriesWrapper = styled.section`
  min-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
`

export const CategoriesList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 64rem;
  margin: 0 auto;
  margin: 0.62rem 0;
  padding-top: 0.62rem;
`

export const CategoriesCard = styled.li`
  display: flex;
  flex-direction: column;
  transition: border 0.5s;
  width: 18.75rem;
  height: 14.69rem;
  margin-right: 1.5rem;

  ${media.lessThan("small")`
    margin-top: 1rem;
    margin-right: 0;
    width: 17.75rem;
  `}

  ${media.lessThan("987px")`
    margin-bottom: 1.5rem;
  `}
`

export const CategoryLinkItem = styled(Link)``

export const CategoriesTitle = styled.h2`
  font-size: 1.31rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.secondFont};
  text-align: center;
  margin-bottom: 0.5rem;
  min-height: 60px;

  ${media.lessThan("small")`
    margin-bottom: 0.5rem;
  `}
`

export const CategoriesPhotoWrapper = styled.div`
  padding: 2px;
  position: relative;
  background: ${colors.containerBackground};
  width: 100%;
  height: 11.91rem;
  border-radius: 20px;

  div.gatsby-image-wrapper {
    border-radius: 20px;
    height: 11.65rem;
  }
`

export const CircleContainer = styled.div`
  position: absolute;
  display: flex;
  background: ${colors.checkColor};
  z-index: 2;
  border-radius: 50%;
  padding: 2px;
  top: -4%;
  left: -4%;

  > svg {
    z-index: 2;
  }
`
