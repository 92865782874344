import React from "react"
import { CheckCircle } from "@styled-icons/fa-solid/CheckCircle"

import Image from "../Image"
import { categoriesData } from "./content"

import {
  CategoriesWrapper,
  CategoriesList,
  CategoriesCard,
  CategoriesTitle,
  CategoriesPhotoWrapper,
  CircleContainer,
  CategoryLinkItem,
} from "./styles"

export default function Category() {
  return (
    <CategoriesWrapper>
      <CategoriesList>
        {categoriesData.map((categoryItem, idx) => (
          <CategoriesCard key={idx}>
            <CategoriesTitle>{categoryItem.title}</CategoriesTitle>
            <CategoryLinkItem to={categoryItem.url}>
              <CategoriesPhotoWrapper>
                <CircleContainer>
                  <CheckCircle color="#fff" size="38" />
                </CircleContainer>
                <Image filename={categoryItem.imageRawName} />
              </CategoriesPhotoWrapper>
            </CategoryLinkItem>
          </CategoriesCard>
        ))}
      </CategoriesList>
    </CategoriesWrapper>
  )
}
