import React from "react"

import { sloganData } from "./content"

import Contact from "../Contact"
import Image from "../Image"

import {
  PanelWrapper,
  PanelSlogan,
  PanelShadow,
  PanelContent,
  ImageWrapper,
  ContactContainer,
  Slogan,
  SloganContainer,
} from "./styles"

export default function SloganFooter() {
  const { contactText, slogan } = sloganData

  return (
    <PanelWrapper>
      <ImageWrapper>
        <Image filename="registradora.png" />
      </ImageWrapper>
      <PanelContent>
        <SloganContainer>
          <Slogan>{slogan}</Slogan>
        </SloganContainer>
        <ContactContainer>
          <PanelSlogan>{contactText}</PanelSlogan>
          <Contact name="home"/>
        </ContactContainer>
      </PanelContent>
      <PanelShadow />
    </PanelWrapper>
  )
}
