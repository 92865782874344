import React, { useState } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { sloganContentData } from "./content"
import './index.css'
import {
  TestimonialWrapper,
  TestimonialTitle,
  TestimonialText,
  TestimonialPerson,
  TestimonialContent,
  ReadMoreButton
} from "./styles"

export default function TestimonialFooter() {
  const content = sloganContentData
  const [readMore, setReadMore] = useState(false)

  return (
    <TestimonialWrapper>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        stopOnHover={true}
        swipeable={true}
        onChange={() => { setReadMore(false) }}
      >
        {content.map(item => (
          <TestimonialContent>
            {/* <TestimonialTitle>Depoimento de Clientes</TestimonialTitle> */}
            <span>
              <TestimonialText>"</TestimonialText>
              <TestimonialText>{item.text.substring(0, 500)}</TestimonialText>
              {!readMore && item.text.length > 500 ? <TestimonialText>...</TestimonialText> : null}
              {readMore ? <TestimonialText>{item.text.substring(500)}</TestimonialText> : null}
              <TestimonialText>"</TestimonialText>
              {!readMore && item.text.length > 500 ? <ReadMoreButton onClick={() => setReadMore(true)}>Ler mais</ReadMoreButton> : null}
            </span>
            <TestimonialPerson>
              <span>{item.author}</span> {item.role}
            </TestimonialPerson>
          </TestimonialContent>
        ))}

      </Carousel>

    </TestimonialWrapper>
  )
}
