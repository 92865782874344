import React, { useState } from "react"
import { Person } from "@styled-icons/material-sharp/Person"
import { Email } from "@styled-icons/material-outlined/Email"
import { LockAlt } from "@styled-icons/boxicons-solid/LockAlt"
// import { toast } from "react-toastify"

// import api from "../../services/api"

import {
  ContactWrapper,
  ContactWrapperInput,
  ContactInputNome,
  ContactInputEmail,
  ContactButtonConfirm,
  ContactMessageFooter,
  ContactContent,
} from "./styles"

export default function Contact(props) {
  // const [contact, setContact] = useState({
  //   name: "",
  //   email: "",
  // })

  // const handleGetContact = event => {
  //   setContact({
  //     ...contact,
  //     [event.target.name]: event.target.value,
  //   })
  // }

  // const handleClick = async () => {
  //   const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

  //   if (emailPattern.test(contact.email)) {
  //     try {
  //       await api.post("/emails", contact)
  //       toast.success("Cadastro enviado com sucesso!")
  //       setContact({ name: "", email: "" })
  //     } catch (error) {
  //       toast.error("Erro ao enviar contato para cadastro...")
  //     }
  //   } else {
  //     toast.error("Verifique se digitou o e-mail corretamente...")
  //   }
  // }
  var optName = {}
  optName['name'] = `contact-${props.name}`;
  var optValue = {}
  optValue['value'] = `contact-${props.name}`;
  return (
    <ContactWrapper 
      action="/success-netlify"
      method="post"
      data-netlify="true"
      onSubmit="submit"
      {...optName}
    >
      <input type="hidden" name="Contato" {...optValue}></input>
      <ContactContent>
        <ContactWrapperInput>
          <ContactInputNome
            placeholder="Nome"
            name="name"
          />
          <Person size={20} color="#686868" />
        </ContactWrapperInput>
        <ContactWrapperInput>
          <ContactInputEmail
            placeholder="E-mail"
            name="email"
          />
          <Email size={20} color="#686868" />
        </ContactWrapperInput>
        <ContactButtonConfirm type="submit">
          Solicite sua Proposta
        </ContactButtonConfirm>
        <ContactMessageFooter>
          <LockAlt size={16} color="#CAA856" />
          <span>Não enviamos Span!</span>
        </ContactMessageFooter>
      </ContactContent> 
    </ContactWrapper>
  )
}
