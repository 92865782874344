import styled from "styled-components"
import { colors } from "../../styles/colors"

export const ContactWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
  z-index: 1;
  padding: 0.5rem;
`

export const ContactContent = styled.div`
  width: 100%;
  max-width: 25rem;
`

export const ContactWrapperInput = styled.div`
  display: flex;
  align-items: center;
  font-size: 17px;
  height: 52px;
  padding: 15px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  margin: 10px 0;
  border-radius: 3px;
  width: 100%;
  background: #fff;
`

export const ContactInputNome = styled.input`
  width: 100%;
  border: 0;
  color: ${colors.inputFont};
`

export const ContactInputEmail = styled.input`
  width: 100%;
  border: 0;
  color: ${colors.inputFont};
`

export const ContactButtonConfirm = styled.button`
  background: linear-gradient(to bottom, #f39d13 0, #c64f01 100%);
  border-radius: 8px;
  padding: 0;
  width: 100%;
  border: 3px solid #f0f0f0;
  border: 1px solid #c94f00;
  text-shadow: 0 1px #bf1802;
  font-size: 20px;
  color: ${colors.mainFont};
  height: 55px;
`

export const ContactMessageFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: center;

  > span {
    margin-left: 5px;
    color: ${colors.inputFont};
    font-weight: 500;
    font-size: 12px;
  }
`
