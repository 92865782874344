export const mainPanelData = {
  impactPhrase:
    "Mais que um serviço contábil, uma orientadora para o seu negócio",
  optionTitle: "Serviços",
  options: [
    "Assessoria Contábil",
    "Assessoria Financeira",
    "Legalização de empresas",
    "Consultoria",
    "Apuração de Impostos",
    "Departamento pessoal",
    "Escrituração Contábil",
  ],
  contactText: "Cadastre-se para ser nosso cliente",
}
