import React from "react"

import SEO from "../components/seo"

import Layout from "../components/Layout"
import MainPanel from "../components/MainPanel"
import PageDivisor from "../components/PageDivisor"
import Categories from "../components/Category"
import SloganFooter from "../components/SloganFooter"
import TestimonialFooter from "../components/TestimonialFooter";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <MainPanel />
    <PageDivisor text="Principais Segmentos que Atendemos" />
    <Categories />
    <TestimonialFooter/>
    <SloganFooter />
  </Layout>
)

export default IndexPage
