import styled from "styled-components"
import media from "styled-media-query"
import { colors } from "../../styles/colors"

export const PanelWrapper = styled.section`
  position: relative;
  min-height: 25rem;
  height: 100%;

  ${media.lessThan("1227px")`
    min-height: 31rem;
  `}

  ${media.lessThan("527px")`
    min-height: 33rem;
  `}

  ${media.lessThan("376px")`
    min-height: 43rem;
  `}
`

export const ImageWrapper = styled.div`
  div.gatsby-image-wrapper {
    min-height: 25rem;
    max-height: 35rem;

    ${media.lessThan("1227px")`
      min-height: 31rem;
    `}

    ${media.lessThan("527px")`
      min-height: 33rem;
    `}

    ${media.lessThan("376px")`
      min-height: 43rem;
    `}
  }
`

export const PanelShadow = styled.div`
  top: 0;
  left: 0;
  background: #000;
  height: 100%;
  width: 100%;
  opacity: 60%;
  position: absolute;
`

export const PanelContent = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  flex-wrap: wrap;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
`

export const PanelSlogan = styled.h2`
  font-size: 1.75rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.mainFont};
  text-align: center;
  z-index: 1;
  max-width: 34.75rem;
`

export const SloganContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;

  ${media.lessThan("1227px")`
    margin-top: 1rem;
    justify-content: center;;
  `}

  ${media.lessThan("small")`
    margin-top: 0.5rem;
  `}
`

export const ContactContainer = styled.div`
  display: flex;
  flex-grow: 1;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 2;

  ${media.lessThan("1227px")`
    margin-top: 1rem;
  `}

  ${media.lessThan("small")`
    margin-top: 0.5rem;
  `}
`

export const Slogan = styled.h2`
  max-width: 40rem;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.mainFont};
  text-align: center;
  z-index: 2;
`
