export const categoriesData = [
  {
    title: "Empresas de Prestação de Serviços",
    imageRawName: "EmpresasDeTecnologia.jpg",
    url: "/servicos",
  },
  {
    title: "Profissionais Liberais",
    imageRawName: "ProfissionaisLiberais.jpg",
    url: "/liberal",
  },
  {
    title: "Saúde e bem estar",
    imageRawName: "ProfissionaisSaude.jpg",
    url: "/saude",
  }

]
