import React from "react";

import Image from "../Image";

import { mainPanelData } from "./content";

import {
    PanelWrapper,
    PanelSlogan,
    PanelSignUp,
    PanelShadow,
    PanelContentLeft,
    ImageWrapper, PanelContentRight,
} from "./styles";
import {CheckCircle} from "@styled-icons/fa-solid/CheckCircle";
import {OptionContainer, OptionItem, Options, OptionTitle} from "../ContactPage/styles";
import {colors} from "../../styles/colors";

export default function MainPanel() {
  const { impactPhrase, contactText, optionTitle, options } = mainPanelData;

  return (
    <PanelWrapper>
      <ImageWrapper>
        <Image filename="bc2-1.png" />
      </ImageWrapper>
        <PanelContentLeft>
            <PanelSlogan><h3>SG Contábil</h3></PanelSlogan>
            <PanelSignUp>
                <p>Um escritório de serviços contábeis e consultoria especializada no atendimento a Profissionais Liberais, Empresas de Prestação de Serviços e de Saúde e Bem Estar.</p>
                <br/>
                <p>Idealizado por <u>Susana Gonçalves</u>, contadora e advogada com mais de 20 anos de experiência no ramo contábil em diversos segmentos.</p>
            </PanelSignUp>
        </PanelContentLeft>
        <PanelContentRight>
            <OptionTitle></OptionTitle>
            <Options>
                {options.map((item, idx) => (
                    <OptionContainer key={idx}>
                        <CheckCircle color={colors.checkColor} size={25} />
                        <OptionItem>{item}</OptionItem>
                    </OptionContainer>
                ))}
            </Options>
        </PanelContentRight>
      <PanelShadow />
    </PanelWrapper>
  );
}
