import styled from "styled-components"
import { colors } from "../../styles/colors"
import media from "styled-media-query"

export const DivisorWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  ${media.lessThan("medium")`
    display: ${props => props.hideSmall ? `none;` : `flex;`};
  `}
  
`

export const DivisorText = styled.h2`
  color: ${colors.main};
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
`

export const DivisorIndicator = styled.div`
  ${props =>
    !props.notIndicator &&
    `
    border: 20px solid transparent;
    border-top-color: ${colors.second};
  `}
`

export const DivisorContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background:${props =>
    props.color || `${colors.second}`
  };
  height: 65px;
`
